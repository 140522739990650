<template>
  <picture
    v-if="image"
    :key="image.id + image.ppoi_tuple"
    class="image-wrapper"
    :style="{
      backgroundColor:
        image.has_transparency || image.is_svg ? 'unset' : image?.dominant_color
    }"
  >
    <img
      ref="img"
      class="image"
      :width="width ?? undefined"
      :height="height ?? undefined"
      :class="{
        cover: fit === 'fill',
        contain: fit === 'fit',
        lazyload,
        lazyloaded: isLoaded
      }"
      :style="imgPositionStyles"
      :src="image.is_svg ? image.file : getThumb(image, width, height)"
      :srcset="srcset"
      :sizes="sizes"
      :alt="image.alt"
      :usemap="usemap"
      :loading="lazyload ? 'lazy' : undefined"
      @load="handleLoad"
    />
  </picture>
</template>

<script lang="ts" setup>
import type { PublicImage } from '@/service/__generated-api'
import { getThumb } from '@/utils/images/imgproxy'

type ResizeType = 'fit' | 'fill' | 'auto'

export type ImgSize = {
  w: number
  h: number
  resize?: ResizeType
}

const props = withDefaults(
  defineProps<{
    image: PublicImage
    width?: number | null
    height?: number | null
    colorPlaceholder?: boolean
    imgSizes?: ImgSize[]
    fit?: ResizeType
    usemap?: string
    lazyload?: boolean
    sizes?: string
  }>(),
  {
    width: undefined,
    height: undefined,
    colorPlaceholder: true,
    imgSizes: () => [],
    fit: 'fill',
    usemap: undefined,
    lazyload: true,
    sizes: ''
  }
)

const img = ref<HTMLImageElement>()
const isLoaded = ref(!!img.value?.complete)

const handleLoad = () => {
  isLoaded.value = true
}

watch(img, value => {
  isLoaded.value = !!value?.complete
})

const srcset = computed(() => {
  if (!props.imgSizes || props.image.is_svg) {
    return
  }
  const srcs: string[] = []
  props.imgSizes.forEach(size => {
    const w = size.w
    const h = size.h
    srcs.push(`${getThumb(props.image, w, h, size.resize)} ${w}w`)
    srcs.push(`${getThumb(props.image, 2 * w, 2 * h, size.resize)} ${2 * w}w`)
  })
  return srcs.join(',')
})

const sizes = computed(() => {
  if (!srcset.value) return
  return props.sizes
})

const imgPositionStyles = computed(() => {
  const [x, y] = props.image.ppoi_tuple as unknown as [number, number]
  return {
    objectPosition: props.fit === 'fit' ? `50% 50%` : `${x * 100}% ${y * 100}%`
  }
})
</script>

<style lang="scss" scoped>
.image-wrapper,
.image {
  display: block;
  width: 100%;
  height: 100%;
}

.image {
  &.cover {
    object-fit: cover;
  }

  &.contain {
    object-fit: contain;
  }

  &.lazyload {
    opacity: 0;
  }

  &.lazyloaded {
    opacity: 1;
    transition: opacity 0.35s ease-out;
  }
}
</style>
